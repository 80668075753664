import Axios from 'axios';
import { getAuthorization } from './config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const sendForm = (firstName, lastName, email, town, company, job, phone,
  inverterType, fundInterest, amountFund, howMeet) => {
  return Axios({
    method: 'post',
    url: `${API_URL}api/investmentinscriptions`,
    data: {
      firstName,
      lastName,
      email,
      town,
      company,
      job,
      phone,
      inverterType,
      fundInterest,
      amountFund,
      howMeet,
    },
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    });
};

export default { sendForm };
