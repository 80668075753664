import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import arrowRight from '../../assets/img/flecha_derecha.svg';
import icon1 from '../../assets/img/icon_inversion_01.svg';
import icon2 from '../../assets/img/icon_inversion_02.svg';
import icon3 from '../../assets/img/icon_inversion_03.svg';

export default function TypesInfoSection() {
  const { t } = useTranslation();

  return (
    <div className="types-info-section">
      <h2 className="title-section">
        {t('typesInfoSection_title')}
      </h2>
      <div>
        <Link to="/funds-overview" className="btn-gral">
          {t('typesInfoSection_button')}
          <img alt="" src={arrowRight} />
        </Link>
      </div>
      <div className="types-box">
        <div>
          <img className="icon-type" alt="" src={icon1} />
          <h4>
            {t('typesInfoSection_subtitle1')}
          </h4>
          <p>
            {t('typesInfoSection_paragraph1')}
          </p>
        </div>
        <div>
          <img className="icon-type" alt="" src={icon2} />
          <h4>
            {t('typesInfoSection_subtitle2')}
          </h4>
          <p>
            {t('typesInfoSection_paragraph2')}
          </p>
        </div>
        <div>
          <img className="icon-type" alt="" src={icon3} />
          <h4>
            {t('typesInfoSection_subtitle3')}
          </h4>
          <p>
            {t('typesInfoSection_paragraph3')}
          </p>
        </div>
      </div>
    </div>
  );
}
