export const TRANSLATIONS_ES = {
  aboutUs_paragraph: 'Sobre la base de este nuevo paradigma, buscamos acercar una forma fácil de invertir a todo el público interesado en el espacio cripto.',
  aboutUs_subtitle: 'Creemos que las finanzas tradicionales llegaron a un punto en el cual han dejado de ser eficientes. La tecnología blockchain surge como una alternativa revolucionaria que permite a los participantes del mercado interactuar de una manera mucho más eficiente.',
  aboutUs_title: 'Nosotros',
  blockchainNotice_button: 'ENVIAR',
  blockchainNotice_paragraph: 'Envía tu perfil para unirte al equipo de Patagonia.',
  blockchainNotice_question: '¿Estás buscando una carrera en blockchain?',
  disclaimer_paragraph1: 'Toda la información y datos obrantes en este sitio web son provistos sólo y exclusivamente con fines informativos. No asumimos ningún tipo de responsabilidad respecto de la exactitud, integridad, idoneidad o validez de dicha información, así como en relación con cualquier error, omisión o cualquier pérdida, lesión o daños provocados por su uso o exhibición. La información obrante en este sitio se provee tal cual se encuentra publicada, sin garantía de ningún tipo y no confiere ningún derecho a quienes se hubieren valido de la misma o la hubieren utilizado de cualquier forma. Asimismo, tampoco asumimos ninguna responsabilidad por cualquier material publicado u obtenido en los sitios web de terceros cuyos enlaces puedan ser publicados en este sitio, la que corresponderá en forma exclusiva a dichos terceros. Los URLS y dominios de este sitio pueden ser transferidos sin necesidad de aviso previo.',
  disclaimer_paragraph2: 'La información contenida en este sitio web no constituye una oferta de venta ni una oferta para comprar cualquier valor, título, producto, instrumento o derivado; tampoco constituye una oferta o compromiso de prestar, sindicar o de cualquier otra manera otorgar financiamiento,suscribir, comprar, actuar como agente, asesor o bajo cualquier otra calidad respecto de ninguna transacción relacionada con títulos valores, ni para comprometer capital o participar en alguna estrategia de negociación de los mismos.',
  disclaimer_paragraph3: 'Debido a que la información en este sitio web se encuentra basada en experiencia y opinión personal de sus autores, la misma no debe ser considerada como asesoramiento legal, regulatorio, contable o fiscal. Recomendamos que los usuarios de este sitio web recurran a asesoramiento legal, regulatorio, contable y fiscal por parte de profesionales independientes e idóneos, respecto del contenido de este sitio web.',
  disclaimer_paragraph4: 'Al navegar por el sitio web Ud. presta su consentimiento libre e informado para que los datos por Ud. suministrados sean procesados, almacenados, transferidos, cedidos y utilizados por Patagonia Capital LLC con domicilio en 530-B Harkle Road, Santa Fe, Nuevo México, Estados Unidos, así como por sus agentes, proveedores y afiliadas.',
  disclaimer_paragraph5: 'Los datos personales podrán ser transferidos y cedidos a cualquier filial o sucursal de Patagonia Capital LLC y/o a cualquier tercero, dentro de Estados Unidos y/o a cualquier otra jurisdicción fuera de Estados Unidos.',
  disclaimer_paragraph6: 'Ud. podrá ejercer sus derechos de acceso, rectificación o supresión de sus datos personales mediante el envío de un email a',
  fixedIncome_button: 'INVERTIR',
  fixedIncome_comision: 'Comisión:',
  fixedIncome_horizon: 'Horizonte de inversión: ',
  fixedIncome_horizonValue: 'Corto, mediano o largo plazo',
  fixedIncome_rate: 'Tasa:',
  fixedIncome_rateValue: 'Fija',
  fixedIncome_rescue: 'Rescate:',
  fixedIncome_rescueValue: 'Mensual, con un mínimo de 5 días de preaviso',
  fixedIncome_risk: 'Riesgo:',
  fixedIncome_riskValue: 'Bajo',
  fixedIncome_subscription: 'Suscripción:',
  fixedIncome_subscriptionType: 'Mensual',
  fixedIncome_subtitle: 'RESUMEN DE TÉRMINOS & CONDICIONES',
  fixedIncome_title: 'Renta Fija',
  footer_about: 'NOSOTROS',
  footer_invest: 'INVERTIR',
  footer_portfolio: 'PORTAFOLIO',
  footerFunds: 'FONDOS',
  footerFundsFixed: 'Renta Fija',
  footerFundsHigh: 'Alto Rendimiento',
  footerFundsLiquid: 'Cripto Líquido',
  footerFundsOverview: 'Visión General',
  footerLocation: 'Nuevo México, Estados Unidos',
  fundsOverview_paragraph1: 'Patagonia proporciona los medios para invertir en todo el espectro del espacio cripto, desde activos altamente líquidos hasta activos ilíquidos con alto potencial.',
  fundsOverview_paragraph2: 'Explorá nuestros fondos a continuación o hacé click',
  fundsOverview_paragraph3: 'acá',
  fundsOverview_paragraph4: 'si estas listo para empezar a invertir.',
  fundsOverview_title: 'Fondos Patagonia',
  fundsOverviewActive: 'Activos:',
  fundsOverviewAssets1: 'Tokens negociados públicamente ',
  fundsOverviewAssets2: 'Tokens estables negociados públicamente',
  fundsOverviewFixedIncome_paragraph: 'Exposición a instrumentos que ofrecen rendimientos en moneda estable cripto.',
  fundsOverviewFixedIncome_title: 'RENTA FIJA',
  fundsOverviewHighYield_paragraph: 'Exposición a una cartera de inversión cuya estrategia se basa predominantemente en el trading algorítmico.',
  fundsOverviewHighYield_title: 'ALTO RENDIMIENTO',
  fundsOverviewLiquid: 'Liquidez:',
  fundsOverviewLiquidCrypto_paragraph: 'Exposición a activos digitales líquidos y de alta capitalización de mercado.',
  fundsOverviewLiquidCrypto_title: 'CRIPTO LÍQUIDO',
  fundsOverviewLiquidity: 'Mensual',
  header_option1: 'Visión General',
  header_option2: 'Cripto Líquido',
  header_option3: 'Renta Fija',
  header_option4: 'Alto Rendimiento',
  header_title1: 'Invertir',
  header_title2: 'fondos',
  header_title3: 'portafolio',
  header_title4: 'nosotros',
  highYield_investmentButton: 'INVERTIR',
  highYield_investmentFees: 'Comisión:',
  highYield_investmentFeesData: 'Fija y variable',
  highYield_investmentRedemptions: 'Rescate: ',
  highYield_investmentRedemptionsData: 'Mensual, con un mínimo de 5 días de preaviso',
  highYield_investmentRisk: 'Riesgo: ',
  highYield_investmentRiskLevel: 'Alto',
  highYield_investmentSubscription: 'Suscripción: ',
  highYield_investmentSubscriptionTime: 'Mensual',
  highYield_investmentSubtitle: 'Corto, mediano o largo plazo',
  highYield_investmentTitle: 'Horizonte de inversión:',
  highYield_paragraph: 'El fondo invierte en una serie de activos digitales con alto potencial, aplicando principalmente una estrategia de trading algorítmica. Adicionalmente, para el remanente de los fondos utiliza una estrategia de trading discrecional enfocada en proyectos dentro del ecosistema de las Finanzas Descentralizadas (DeFi) y en otros sectores altamente innovadores del espacio blockchain.',
  highYield_subtitle: 'RESUMEN DE TÉRMINOS & CONDICIONES',
  highYield_title: 'Alto Rendimiento',
  invest_about: '¿CÓMO NOS CONOCISTE?*',
  invest_aboutQuestion1: 'Búsqueda Online',
  invest_aboutQuestion2: 'Empleado de Patagonia',
  invest_aboutQuestion3: 'Referencia Externa',
  invest_aboutQuestion4: 'Conferencia / Evento',
  invest_aboutQuestion5: 'Prensa',
  invest_button: 'ENVIAR',
  invest_city: 'CIUDAD',
  invest_company: 'NOMBRE DE COMPAÑÍA',
  invest_funds: '¿CUÁNTO CAPITAL QUERÉS ALOCAR A LOS FONDOS?*',
  invest_interests: '¿EN QUÉ FONDOS ESTÁS INTERESADO?*',
  invest_interestsOption1: 'Cripto Líquido',
  invest_interestsOption2: 'Renta Fija',
  invest_interestsOption3: 'Alto Rendimiento',
  invest_investorOption1: 'Asesor*',
  invest_investorOption2: 'Inversor Individual*',
  invest_investorOption3: 'Inversor Institucional*',
  invest_investorType: 'TIPO DE INVERSOR*',
  invest_lastname: 'APELLIDO*',
  invest_name: 'NOMBRE*',
  invest_paragraph: 'Si estas interesado en obtener más información o queres invertir en alguno de nuestros fondos, completa el formulario a continuación y nos pondremos en contacto.',
  invest_phone: 'TELÉFONO*',
  invest_title: 'Fondos Patagonia',
  invest_workTitle: 'TÍTULO DE TRABAJO',
  liquidCrypto_title: 'Cripto Líquido',
  liquidCrypto_paragraph: 'Cartera de inversión compuesta por 5 a 10 tokens líquidos con proyectos bien establecidos y de alto potencial dentro del espacio blockchain.',
  liquidCrypto_subtitle: 'RESUMEN DE TÉRMINOS & CONDICIONES',
  liquidCrypto_rebalacing: 'Rebalanceo de cartera:',
  liquidCrypto_horizon: 'Horizonte de inversión:',
  liquidCrypto_risk: 'Riesgo:',
  liquidCrypto_sub: 'Suscripción:',
  liquidCrypto_redemptions: 'Rescate:',
  liquidCrypto_fees: 'Comisión:',
  liquidCrypto_monthly: 'Mensual',
  liquidCrypto_term: 'Mediano o largo plazo',
  liquidCrypto_medium: 'Medio',
  liquidCrypto_redemMonthly: 'Mensual, con un mínimo de 5 días de preaviso',
  liquidCrypto_fixed: 'Fija y variable',
  liquidCrypto_button: 'INVERTIR',
  opportunitiesSection_paragraph1: 'Medio para diversificar riesgo respecto de las inversiones tradicionales, con la posibilidad de obtener altos rendimientos.',
  opportunitiesSection_paragraph2: 'Reserva de valor alternativa de carácter global, útil para proteger la riqueza de crisis económicas, gobiernos, devaluación de la moneda y la volatilidad de los mercados financieros.',
  opportunitiesSection_paragraph3: 'Los principales bancos e instituciones financieras están comenzando a invertir en tecnologías blockchain, aunque aún marginalmente. Sin embargo, esto no durará mucho tiempo y pronto los inversores institucionales entrarán al mercado generando una mayor consolidación del espacio cripto.',
  opportunitiesSection_title: 'Oportunidad de inversión',
  portfolio_paragraph: 'Invertimos en proyectos que creemos cambiarán el mundo en el cual vivimos. Esto va desde proyectos enfocados en las Finanzas Descentralizadas hasta exchanges, custodios, herramientas de trading, etc.',
  portfolio_sector_decentralizedFinance: 'Finanzas Descentralizadas',
  portfolio_sector_finance: 'Finanzas',
  portfolio_sector_infrastructure: 'Infraestructura',
  portfolio_subtitle1: 'Nombre',
  portfolio_subtitle2: 'Sector',
  portfolio_title: 'Una cartera global para una inversión global',
  typesInfoSection_button: 'IR A FONDOS',
  typesInfoSection_paragraph1: 'Exposición a activos digitales líquidos y de alta capitalización de mercado. Cartera compuesta por proyectos bien establecidos y con alto potencial dentro del espacio blockchain',
  typesInfoSection_paragraph2: 'Portafolio de inversión que ofrece rendimientos fijos en moneda estable cripto.',
  typesInfoSection_paragraph3: 'Exposición a una cartera de inversión que aplica el trading algorítmico como principal estrategia.',
  typesInfoSection_subtitle1: 'CRIPTO LÍQUIDO',
  typesInfoSection_subtitle2: 'Renta Fija',
  typesInfoSection_subtitle3: 'ALTO RENDIMIENTO',
  typesInfoSection_title: 'Tipos de inversión',
  welcome_subtitle: 'Patagonia ofrece a los inversores el espectro completo de exposición al espacio cripto',
  welcome_title: 'Gestor de inversiones en criptoactivos enfocado en tecnología blockchain',
  fixedIncome_paragraph: 'Cartera de inversión enfocada en instrumentos que otorgan renta en moneda estable cripto',
};

export default TRANSLATIONS_ES;
