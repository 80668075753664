import React from 'react';
import './index.scss';
import Welcome from '../../components/welcome';
import OpportunitiesSection from '../../components/opportunities-section';
import TypesInfoSection from '../../components/types-info-section';
import BlockchainNotice from '../../components/blockchain-notice';

export default function Home() {
  return (
    <div>
      <Welcome />
      <OpportunitiesSection />
      <TypesInfoSection />
      <BlockchainNotice />
    </div>
  );
}
