import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.scss';

export default function LiquidCrypto() {
  const { t } = useTranslation();
  return (
    <div className="submenu-funds-page">
      <div className="submenu-funds-content">
        <div className="left-box">
          <h2 className="title-page">
            Patagonia
            <br />
            {t('liquidCrypto_title')}
          </h2>
          <p className="subtitle-page">
            {t('liquidCrypto_paragraph')}
          </p>
        </div>
        <div className="right-box">
          <h3>{t('liquidCrypto_subtitle')}</h3>
          <div className="table">
            <div className="row">
              <b>{t('liquidCrypto_rebalacing')}</b>
              <span>{t('liquidCrypto_monthly')}</span>
            </div>
            <div className="row">
              <b>{t('liquidCrypto_horizon')}</b>
              <span>{t('liquidCrypto_term')}</span>
            </div>
            <div className="row">
              <b>{t('liquidCrypto_risk')}</b>
              <span>{t('liquidCrypto_medium')}</span>
            </div>
            <div className="row">
              <b>{t('liquidCrypto_sub')}</b>
              <span>{t('liquidCrypto_monthly')}</span>
            </div>
            <div className="row">
              <b>{t('liquidCrypto_redemptions')}</b>
              <span>{t('liquidCrypto_redemMonthly')}</span>
            </div>
            <div className="row">
              <b>{t('liquidCrypto_fees')}</b>
              <span>{t('liquidCrypto_fixed')}</span>
            </div>
          </div>
          <Link className="btn-gral" to="/invest">{t('liquidCrypto_button')}</Link>
        </div>
      </div>
    </div>
  );
}
