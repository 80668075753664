import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import bitcoin from '../../assets/img/porfolio/01-bitcoin.png';
import ethereum from '../../assets/img/porfolio/02-ethereum.png';
import chainlink from '../../assets/img/porfolio/03-chainlink.png';
import polkadot from '../../assets/img/porfolio/04-polkadot.png';
import cardano from '../../assets/img/porfolio/05-cardano.png';
import polygon from '../../assets/img/porfolio/06-polygon.png';
import vechain from '../../assets/img/porfolio/07-vechain.png';
import ripple from '../../assets/img/porfolio/08-ripple.png';
import solana from '../../assets/img/porfolio/09-solana.png';
import theGraph from '../../assets/img/porfolio/10-the-graph.png';
import oneinch from '../../assets/img/porfolio/12-1inch.png';
import pancakeswap from '../../assets/img/porfolio/13-pancakeswap.png';
import filecoin from '../../assets/img/porfolio/14-filecoin.png';
import theta from '../../assets/img/porfolio/15-theta.png';
import circle from '../../assets/img/porfolio/16-circle.png';
import uniswap from '../../assets/img/porfolio/17-uniswap.png';
import aave from '../../assets/img/porfolio/18-aave.png';
import tron from '../../assets/img/porfolio/19-tron.png';
import ampleforth from '../../assets/img/porfolio/20-ampleforth.png';

export default function Portfolio() {
  const { t } = useTranslation();
  return (
    <div className="portfolio-page">
      <div className="portfolio-content">
        <div className="portfolio-title-section">
          <h2 className="title-page">
            {t('portfolio_title')}
          </h2>
          <p className="subtitle-page">
            {t('portfolio_paragraph')}
          </p>
        </div>
        <div className="custom-table">
          <div className="row head">
            <span>{t('portfolio_subtitle1')}</span>
            <span>{t('portfolio_subtitle2')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="bitcoin" src={bitcoin} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="ethereum" src={ethereum} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="chainlink" src={chainlink} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="polkadot" src={polkadot} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="cardano" src={cardano} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="polygon" src={polygon} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="vechain" src={vechain} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="ripple" src={ripple} />
            </span>
            <span>{t('portfolio_sector_finance')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="solana" src={solana} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="theGraph" src={theGraph} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="1inch" src={oneinch} />
            </span>
            <span>{t('portfolio_sector_decentralizedFinance')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="pancakeswap" src={pancakeswap} />
            </span>
            <span>{t('portfolio_sector_decentralizedFinance')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="filecoin" src={filecoin} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="theta" src={theta} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="circle" src={circle} />
            </span>
            <span>{t('portfolio_sector_finance')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="uniswap" src={uniswap} />
            </span>
            <span>{t('portfolio_sector_decentralizedFinance')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="aave" src={aave} />
            </span>
            <span>{t('portfolio_sector_decentralizedFinance')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="tron" src={tron} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
          <div className="row">
            <span>
              <img alt="ampleforth" src={ampleforth} />
            </span>
            <span>{t('portfolio_sector_infrastructure')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
