import React from 'react';
import './translations/i18n';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/scroll-to-top';
import Home from './pages/home';
import Invest from './pages/invest';
import Portfolio from './pages/portfolio';
import AboutUs from './pages/about-us';
import FundsOverview from './pages/funds-overview';
import LiquidCrypto from './pages/liquid-crypto';
import FixedIncome from './pages/fixed-income';
import HighYield from './pages/high-yield';
import Header from './components/header';
import Footer from './components/footer';
import Disclaimer from './components/disclaimer';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <>
          <ScrollToTop />
          <Header />
          <Route exact path="/" component={Home} />
          <Route exact path="/invest" component={Invest} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/funds-overview" component={FundsOverview} />
          <Route exact path="/liquid-crypto" component={LiquidCrypto} />
          <Route exact path="/fixed-income" component={FixedIncome} />
          <Route exact path="/high-yield" component={HighYield} />
          <Disclaimer />
          <Footer />
        </>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
