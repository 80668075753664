import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import flechaFondos from '../../assets/img/flecha_derecha_black.svg';

export default function FundsOverview() {
  const { t } = useTranslation();
  return (
    <div className="funds-overview-page">
      <div className="funds-overview-content">
        <h2 className="title-page">{t('fundsOverview_title')}</h2>
        <p className="subtitle-page">
          {t('fundsOverview_paragraph1')}
        </p>
        <p className="subtitle-page">
          {t('fundsOverview_paragraph2')}
          {' '}
          <Link className="link" to="/invest">{t('fundsOverview_paragraph3')}</Link>
          {' '}
          {t('fundsOverview_paragraph4')}
        </p>
        <div className="funds-table">
          <div className="scroll-mobile">
            <div className="row">
              <Link to="/liquid-crypto">
                <div className="name">{t('fundsOverviewLiquidCrypto_title')}</div>
                <div className="details">
                  <p>
                    {t('fundsOverviewLiquid')}
                    {' '}
                    <b>{t('fundsOverviewLiquidity')}</b>
                  </p>
                  <p>
                    {t('fundsOverviewActive')}
                    {' '}
                    <b>{t('fundsOverviewAssets1')}</b>
                  </p>
                </div>
                <div className="descr">
                  {t('fundsOverviewLiquidCrypto_paragraph')}
                </div>
                <div className="arrow">
                  <img alt="" src={flechaFondos} />
                </div>
              </Link>
            </div>
            <div className="row">
              <Link to="/fixed-income">
                <div className="name">{t('fundsOverviewFixedIncome_title')}</div>
                <div className="details">
                  <p>
                    {t('fundsOverviewLiquid')}
                    {' '}
                    <b>{t('fundsOverviewLiquidity')}</b>
                  </p>
                  <p>
                    {t('fundsOverviewActive')}
                    {' '}
                    <b>
                      {t('fundsOverviewAssets2')}
                    </b>
                  </p>
                </div>
                <div className="descr">
                  {t('fundsOverviewFixedIncome_paragraph')}
                </div>
                <div className="arrow">
                  <img alt="" src={flechaFondos} />
                </div>
              </Link>
            </div>
            <div className="row">
              <Link to="/high-yield">
                <div className="name">{t('fundsOverviewHighYield_title')}</div>
                <div className="details">
                  <p>
                    {t('fundsOverviewLiquid')}
                    {' '}
                    <b>{t('fundsOverviewLiquidity')}</b>
                  </p>
                  <p>
                    {t('fundsOverviewActive')}
                    {' '}
                    <b>
                      {t('fundsOverviewAssets1')}
                    </b>
                  </p>
                </div>
                <div className="descr">
                  {t('fundsOverviewHighYield_paragraph')}
                </div>
                <div className="arrow">
                  <img alt="" src={flechaFondos} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
