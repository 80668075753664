import React, { useState, useEffect } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendForm } from '../../services/investment-inscription';

export default function Invest() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [town, setTown] = useState('');
  const [company, setCompany] = useState('');
  const [job, setJob] = useState('');
  const [phone, setPhone] = useState('');
  const [inverterType, setInverterType] = useState('');
  const [fundInterest, setFundInterest] = useState('');
  const [amountFund, setAmountFund] = useState('');
  const [howMeet, setHowMeet] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  function sendNewForm() {
    return sendForm(firstName, lastName, email, town,
      company, job, phone, inverterType, fundInterest, amountFund, howMeet)
      .then((data) => {
        if (data.error) {
          setError(true);
        } else {
          setSuccess(true);
          setTimeout(() => {
            history.push('/');
          }, 4000);
        }
      });
  }

  function msgError() {
    if (error) {
      return (
        <p className="message-error"> Error al enviar formulario</p>
      );
    }
    return null;
  }
  function msgSuccess() {
    if (success) {
      return (
        <p className="message-success"> Se ha enviado el formulario</p>
      );
    }
    return null;
  }

  useEffect(() => {
    if (firstName && lastName !== '' && email !== '' && (phone !== '' && Number.isInteger(parseInt(phone, 10))) && inverterType !== '' && amountFund !== '' && howMeet !== '') {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [firstName, lastName, email, phone, inverterType, fundInterest, amountFund, howMeet]);
  const { t } = useTranslation();
  return (
    <div className="invest-component">
      <div className="invest-content">
        <div className="invest-left-box">
          <h2 className="title-page">{t('invest_title')}</h2>
          <p className="subtitle-page">
            {t('invest_paragraph')}
          </p>
        </div>
        <div className="invest-right-box">
          <div className="input-line">
            <input type="text" placeholder={t('invest_name')} onChange={(e) => { setFirstName(e.target.value); }} />
            <input type="text" placeholder={t('invest_lastname')} onChange={(e) => { setLastName(e.target.value); }} />
          </div>
          <div className="input-line">
            <input type="text" placeholder="EMAIL*" onChange={(e) => { setEmail(e.target.value); }} />
            <input type="text" placeholder={t('invest_city')} onChange={(e) => { setTown(e.target.value); }} />
          </div>
          <div className="input-line">
            <input type="text" placeholder={t('invest_company')} onChange={(e) => { setCompany(e.target.value); }} />
            <input type="text" placeholder={t('invest_workTitle')} onChange={(e) => { setJob(e.target.value); }} />
          </div>
          <div className="input-line">
            <input type="number" placeholder={t('invest_phone')} onChange={(e) => { setPhone(e.target.value); }} />
          </div>
          <div className="input-line">
            <div className="custom-select">
              <select name="" id="" onChange={(e) => { setInverterType(e.target.value); }}>
                <option selected disabled>{t('invest_investorType')}</option>
                <option>{t('invest_investorOption1')}</option>
                <option>{t('invest_investorOption2')}</option>
                <option>{t('invest_investorOption3')}</option>
              </select>
            </div>
          </div>
          <div className="input-line">
            <div className="custom-select">
              <select name="" id="" onChange={(e) => { setFundInterest(e.target.value); }}>
                <option selected disabled>{t('invest_interests')}</option>
                <option>{t('invest_interestsOption1')}</option>
                <option>{t('invest_interestsOption2')}</option>
                <option>{t('invest_interestsOption3')}</option>
              </select>
            </div>
          </div>
          <div className="input-line">
            <div className="custom-select">
              <select name="" id="" onChange={(e) => { setAmountFund(e.target.value); }}>
                <option selected disabled>{t('invest_funds')}</option>
                <option>US$ 0 – 10 k</option>
                <option>US$ 11 – 50 k</option>
                <option>US$ 51 – 200 k</option>
                <option>US$ + 200 k</option>
              </select>
            </div>
          </div>
          <div className="input-line">
            <div className="custom-select">
              <select name="" id="" onChange={(e) => { setHowMeet(e.target.value); }}>
                <option selected disabled>{t('invest_about')}</option>
                <option>{t('invest_aboutQuestion1')}</option>
                <option>{t('invest_aboutQuestion2')}</option>
                <option>{t('invest_aboutQuestion3')}</option>
                <option>{t('invest_aboutQuestion4')}</option>
                <option>{t('invest_aboutQuestion5')}</option>
              </select>
            </div>
          </div>
          {msgError()}
          {msgSuccess()}
          <button type="button" className={`btn-gral ${!submitEnabled ? 'disabled' : ''}`} onClick={() => sendNewForm()}>{t('invest_button')}</button>
        </div>
      </div>
    </div>
  );
}
