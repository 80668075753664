import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

export default function Welcome() {
  const { t } = useTranslation();

  return (
    <div className="welcome-component">
      <h2>
        {t('welcome_title')}
      </h2>
      <p>
        {t('welcome_subtitle')}
      </p>
    </div>
  );
}
