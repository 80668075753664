import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../liquid-crypto/index.scss';

export default function HighYield() {
  const { t } = useTranslation();
  return (
    <div className="submenu-funds-page">
      <div className="submenu-funds-content">
        <div className="left-box">
          <h2 className="title-page">
            Patagonia
            <br />
            {t('highYield_title')}
          </h2>
          <p className="subtitle-page">
            {t('highYield_paragraph')}
          </p>
        </div>
        <div className="right-box">
          <h3>{t('highYield_subtitle')}</h3>
          <div className="table">
            <div className="row">
              <b>{t('highYield_investmentTitle')}</b>
              <span>{t('highYield_investmentSubtitle')}</span>
            </div>
            <div className="row">
              <b>{t('highYield_investmentRisk')}</b>
              <span>{t('highYield_investmentRiskLevel')}</span>
            </div>
            <div className="row">
              <b>{t('highYield_investmentSubscription')}</b>
              <span>{t('highYield_investmentSubscriptionTime')}</span>
            </div>
            <div className="row">
              <b>{t('highYield_investmentRedemptions')}</b>
              <span>{t('highYield_investmentRedemptionsData')}</span>
            </div>
            <div className="row">
              <b>{t('highYield_investmentFees')}</b>
              <span>{t('highYield_investmentFeesData')}</span>
            </div>
          </div>
          <Link className="btn-gral" to="/invest">{t('highYield_investmentButton')}</Link>
        </div>
      </div>
    </div>
  );
}
