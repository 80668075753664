import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

export default function OpportunitiesSection() {
  const { t } = useTranslation();
  return (
    <div className="opportunities-section">
      <h3 className="title-section">
        {t('opportunitiesSection_title')}
      </h3>
      <div className="opportunities-box">
        <ul>
          <li>
            {t('opportunitiesSection_paragraph1')}
          </li>
          <li>
            {t('opportunitiesSection_paragraph2')}
          </li>
          <li>
            {t('opportunitiesSection_paragraph3')}
          </li>
        </ul>
      </div>
    </div>
  );
}
