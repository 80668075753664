export const TRANSLATIONS_EN = {
  aboutUs_paragraph: 'Based on this new paradigm, we want to bring everybody an easy access to invest in the crypto space.',
  aboutUs_subtitle: 'We believe the traditional finance has come to a point where it\'s no longer efficient. The blockchain technologies surge as a revolutionary alternative that allows market participants to interact in a much more efficient way.',
  aboutUs_title: 'About',
  blockchainNotice_button: 'SEND',
  blockchainNotice_paragraph: 'Submit your profile to join our team',
  blockchainNotice_question: 'Looking for a career in blockchain?',
  disclaimer_paragraph1: 'All information and data on this website is for informational purposes only. We make no representations as to the accuracy, completeness, suitability, or validity, of any information. We will not be liable for any errors, omissions, or any losses, injuries, or damages arising from its display or use. All information is provided as is with no warranties, and confers no rights. We will not be responsible for any material that is found at the end of links that I may post. URLS and domains may change hands.',
  disclaimer_paragraph2: 'The information contained in this website does not constitute an offer to sell or the solicitation of an offer to buy any security, commodity or instrument or related derivative, nor do they constitute an offer or commitment to lend, syndicate or arrange a financing, underwrite or purchase or act as an agent or advisor or in any other capacity with respect to any transaction, or commit capital, or to participate in any trading strategies.',
  disclaimer_paragraph3: 'Because the information in this website is based on personal opinion and experience, it should not be considered professional legal, regulatory, accounting or tax advice. We recommend that the users of this website seek independent third party legal, regulatory, accounting and tax advice regarding the contents of this website.',
  disclaimer_paragraph4: 'By browsing this web site, you give your free and informed consent so that the data you provide can be processed, stored, transferred, assigned, and used by Patagonia Capital LLC domiciled in 530-B Harkle Road, Santa Fe, New Mexico, United States, as well as by its agents, suppliers, and affiliates.',
  disclaimer_paragraph5: 'The personal data may be transferred and assigned to any subsidiary or affiliate of Patagonia Capital LLC and/or any third party, within the United States and/or to any other jurisdiction outside the United States.',
  disclaimer_paragraph6: 'You may exercise your rights of access, rectification, or deletion of your personal data by sending an',
  fixedIncome_button: 'INVEST',
  fixedIncome_comision: 'Fees:',
  fixedIncome_horizon: 'Investment horizon: ',
  fixedIncome_horizonValue: 'Short, medium or long term',
  fixedIncome_rate: 'Rate:',
  fixedIncome_rateValue: 'Fixed',
  fixedIncome_rescue: 'Redemptions:',
  fixedIncome_rescueValue: 'Monthly, upon 5 days’ notice minimum',
  fixedIncome_risk: 'Risk:',
  fixedIncome_riskValue: 'Low',
  fixedIncome_subscription: 'Subscriptions:',
  fixedIncome_subscriptionType: 'Monthly',
  fixedIncome_paragraph: 'Investment portfolio focused exclusively on stablecoin income products',
  fixedIncome_title: 'Fixed Income',
  footer_about: 'ABOUT',
  footer_invest: 'INVEST',
  footer_portfolio: 'PORTFOLIO',
  footerFunds: 'FUNDS',
  footerFundsFixed: 'Fixed Income',
  footerFundsHigh: 'High Yield',
  footerFundsLiquid: 'Liquid Crypto',
  footerFundsOverview: 'Funds Overview',
  footerLocation: 'New Mexico, United States',
  fundsOverview_paragraph1: 'Patagonia provides the means to invest in the full spectrum of the crypto space, ranging from high liquidity assets to more illiquid assets with high potential.',
  fundsOverview_paragraph2: 'Explore our funds below or click',
  fundsOverview_paragraph3: 'here',
  fundsOverview_paragraph4: 'if you are ready to start investing.',
  fundsOverview_title: 'Patagonia Funds',
  fundsOverviewActive: 'Assets:',
  fundsOverviewAssets1: 'Publicly traded tokens',
  fundsOverviewAssets2: 'Stable publicly traded tokens',
  fundsOverviewFixedIncome_paragraph: 'Exposure to products that provide income in stablecoins ',
  fundsOverviewFixedIncome_title: 'FIXED INCOME',
  fundsOverviewHighYield_paragraph: 'Exposure to an investment portfolio that applies algorithmic trading',
  fundsOverviewHighYield_title: 'HIGH YIELD',
  fundsOverviewLiquid: 'Liquidity:',
  fundsOverviewLiquidCrypto_paragraph: 'Exposure to liquid and high market cap digital assets',
  fundsOverviewLiquidCrypto_title: 'LIQUID CRYPTO',
  fundsOverviewLiquidity: 'Monthly',
  header_option1: 'Overview',
  header_option2: 'Liquid Crypto',
  header_option3: 'Fixed Income',
  header_option4: 'High Yield',
  header_title1: 'Invest',
  header_title2: 'funds',
  header_title3: 'portfolio',
  header_title4: 'about',
  highYield_investmentButton: 'INVEST',
  highYield_investmentFees: 'Fees:',
  highYield_investmentFeesData: 'Fixed and variable',
  highYield_investmentRedemptions: 'Redemptions: ',
  highYield_investmentRedemptionsData: 'Monthly, upon 5 days’ notice minimum',
  highYield_investmentRisk: 'Risk: ',
  highYield_investmentRiskLevel: 'High',
  highYield_investmentSubscription: 'Subscriptions: ',
  highYield_investmentSubscriptionTime: 'Monthly',
  highYield_investmentSubtitle: 'Short, medium or long term',
  highYield_investmentTitle: 'Investment horizon:',
  highYield_paragraph: 'The fund invests in high potential projects applying mainly an algorithmic trading strategy. The remainder of the fund uses a discretionary trading strategy focused on projects within the Decentralized Finance (DeFi) space and other blockchain innovative sectors.',
  highYield_subtitle: 'Summary of Terms & Conditions',
  fixedIncome_subtitle: 'Summary of Terms & Conditions',
  highYield_title: 'High Yield',
  invest_about: 'HOW DID YOU FIND US?*',
  invest_aboutQuestion1: 'Online Search',
  invest_aboutQuestion2: 'Patagonia Employee',
  invest_aboutQuestion3: 'External Reference',
  invest_aboutQuestion4: 'Conference / Event',
  invest_aboutQuestion5: 'Press',
  invest_button: 'SEND',
  invest_city: 'CITY',
  invest_company: 'COMPANY NAME',
  invest_funds: 'HOW MUCH CAPITAL DO YOU WANT TO ALLOCATE?*',
  invest_interests: 'IN WHAT FUND ARE YOU INTERESTED?*',
  invest_interestsOption1: 'Liquid Crypto',
  invest_interestsOption2: 'Fixed Income',
  invest_interestsOption3: 'High Yield',
  invest_investorOption1: 'Adviser*',
  invest_investorOption2: 'Individual Investor*',
  invest_investorOption3: 'Institutional Investor*',
  invest_investorType: 'INVESTOR TYPE*',
  invest_lastname: 'LAST NAME*',
  invest_name: 'NAME*',
  invest_paragraph: 'If you are interested in learning more or want to invest in any of our funds, please fill out the form below and we will contact you.',
  invest_phone: 'PHONE*',
  invest_title: 'Patagonia Funds',
  invest_workTitle: 'WORK TITLE',
  liquidCrypto_title: 'Liquid Crypto',
  liquidCrypto_paragraph: 'Investment portfolio composed of 5 to 10 liquid tokens with high potential projects within de blockchain space.',
  liquidCrypto_subtitle: 'Summary of Terms & Conditions',
  liquidCrypto_rebalacing: 'Portfolio rebalancing:',
  liquidCrypto_horizon: 'Investment horizon:',
  liquidCrypto_risk: 'Risk:',
  liquidCrypto_sub: 'Subscriptions:',
  liquidCrypto_redemptions: 'Redemptions:',
  liquidCrypto_fees: 'Fees:',
  liquidCrypto_monthly: 'Monthly',
  liquidCrypto_term: 'Medium or long term',
  liquidCrypto_medium: 'Medium',
  liquidCrypto_redemMonthly: 'Monthly, upon 5 days’ notice minimum',
  liquidCrypto_fixed: 'Fixed and variable',
  liquidCrypto_button: 'INVEST',
  opportunitiesSection_paragraph1: 'Mean to diversify risk from traditional investments with potential high return.',
  opportunitiesSection_paragraph2: 'Global alternative store of value to protect wealth from economic crises, governments, currency devaluation and volatile international financial markets.',
  opportunitiesSection_paragraph3: 'Major banks and financial institutions are starting to invest in blockchain technologies, but not heavily. However, this will not last long and soon institutional investors will enter the market consolidating it even more.',
  opportunitiesSection_title: 'Investment opportunity',
  portfolio_paragraph: 'We invest in projects that we think could change the world we live in. This goes from Decentralized Finance to exchanges, custodians, trading tools, etc.',
  portfolio_sector_decentralizedFinance: 'Decentralized Finance',
  portfolio_sector_finance: 'Finance',
  portfolio_sector_infrastructure: 'Infrastructure',
  portfolio_subtitle1: 'Name',
  portfolio_subtitle2: 'Sector',
  portfolio_title: 'A global portfolio for a global investment',
  typesInfoSection_button: 'Go to Funds',
  typesInfoSection_paragraph1: 'Exposure to liquid and high market cap digital assets. Portfolio of well-established and high potential assets within the blockchain space.',
  typesInfoSection_paragraph2: 'Investment portfolio that provides fixed income in stable crypto currency.',
  typesInfoSection_paragraph3: 'Exposure to an investment portfolio predominantly driven by an algorithmic trading strategy.',
  typesInfoSection_subtitle1: 'Liquid Crypto',
  typesInfoSection_subtitle2: 'Fixed Income',
  typesInfoSection_subtitle3: 'High Yield',
  typesInfoSection_title: 'Investment types',
  welcome_subtitle: 'Patagonia provides investors with the full spectrum of exposure to the crypto space',
  welcome_title: 'Crypto asset manager focused on blockchain technology',
};

export default TRANSLATIONS_EN;
