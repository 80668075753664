import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import arrowDown from '../../assets/img/flecha_abajo.svg';
import world from '../../assets/img/mundo.svg';
import menuMobileImg from '../../assets/img/menu_mobile.svg';
import i18n from '../../translations/i18n';

export default function Header() {
  const [menuMobile, setMenuMobile] = useState('');
  const { t } = useTranslation();
  function changeLanguage(language) {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  }
  return (
    <div className="header-container">
      <button
        type="button"
        className="menu-mobile-btn"
        onClick={() => setMenuMobile('active-menu')}
      >
        <img alt="" src={menuMobileImg} />
      </button>
      <button type="button" className={`background-menu-mobile ${menuMobile}`} onClick={() => setMenuMobile(' ')}>
        <div />
      </button>
      <div className="logo">
        <a href="/">
          <svg className="logo-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.53 90.53">
            <g>
              <rect className="cls-1" width="90.53" height="90.53" fill="red" />
              <rect className="cls-2" x="16.15" y="14.29" width="8.15" height="61.95" />
              <rect className="cls-2" x="35.41" y="68.09" width="38.97" height="8.15" />
              <rect className="cls-2" x="35.54" y="14.29" width="38.84" height="8.15" />
              <polygon className="cls-2" points="66.23 39.22 35.54 39.22 35.54 47.37 74.38 47.37 74.38 29.64 66.23 29.64 66.23 39.22" />
            </g>
          </svg>
        </a>
      </div>
      <ul className={menuMobile}>
        <li>
          <NavLink to="/invest" exact activeClassName="active">
            {t('header_title1')}
          </NavLink>
        </li>
        <li className="dropdown-container">
          {t('header_title2')}
          <img className="arrow-down" alt="" src={arrowDown} />
          <div className="sub-menu">
            <Link to="/funds-overview">{t('header_option1')}</Link>
            <Link to="/liquid-crypto">{t('header_option2')}</Link>
            <Link to="/fixed-income">{t('header_option3')}</Link>
            <Link to="/high-yield">{t('header_option4')}</Link>
          </div>
        </li>
        <li><NavLink to="/portfolio" exact activeClassName="active">{t('header_title3')}</NavLink></li>
        <li><NavLink to="/about-us" exact activeClassName="active">{t('header_title4')}</NavLink></li>
        <li className="dropdown-container">
          <img className="world-icon" alt="" src={world} />
          <div className="sub-menu">
            <button className="button-language" onClick={() => changeLanguage('en')} type="button">EN</button>
            <button className="button-language" onClick={() => changeLanguage('es')} type="button">ES</button>
          </div>
        </li>
      </ul>
      <div className={`right-box ${menuMobile}`}>
        <a href="https://dash.patagoniacapital.net" className="btn-gral">Login </a>
      </div>
    </div>
  );
}
