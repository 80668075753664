import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../liquid-crypto/index.scss';

export default function FixedIncome() {
  const { t } = useTranslation();

  return (
    <div className="submenu-funds-page">
      <div className="submenu-funds-content">
        <div className="left-box">
          <h2 className="title-page">
            Patagonia
            <br />
            {t('fixedIncome_title')}
          </h2>
          <p className="subtitle-page">
            {t('fixedIncome_paragraph')}
          </p>
        </div>
        <div className="right-box">
          <h3>{t('fixedIncome_subtitle')}</h3>
          <div className="table">
            <div className="row">
              <b>{t('fixedIncome_rate')}</b>
              <span>{t('fixedIncome_rateValue')}</span>
            </div>
            <div className="row">
              <b>{t('fixedIncome_horizon')}</b>
              <span>{t('fixedIncome_horizonValue')}</span>
            </div>
            <div className="row">
              <b>{t('fixedIncome_risk')}</b>
              <span>{t('fixedIncome_riskValue')}</span>
            </div>
            <div className="row">
              <b>{t('fixedIncome_subscription')}</b>
              <span>{t('fixedIncome_subscriptionType')}</span>
            </div>
            <div className="row">
              <b>{t('fixedIncome_rescue')}</b>
              <span>{t('fixedIncome_rescueValue')}</span>
            </div>
            <div className="row">
              <b>{t('fixedIncome_comision')}</b>
              <span>-</span>
            </div>
          </div>
          <Link className="btn-gral" to="/invest">{t('fixedIncome_button')}</Link>
        </div>
      </div>
    </div>
  );
}
