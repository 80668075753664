import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.scss';
import iconLinkedin from '../../assets/img/linkedin.svg';
import logoFooter from '../../assets/img/logo_footer.svg';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="content">
        <div className="left-content">
          <div className="logo">
            <img alt="logo" src={logoFooter} />
          </div>
          <div className="social-media">
            <a href="https://www.linkedin.com/company/patagonia-capital">
              <img alt="" src={iconLinkedin} />
              LinkedIn
            </a>
            <br />
            <span className="address">
              530-B Harkle Road STE 100 Santa Fe
              <br />
              {t('footerLocation')}
            </span>
          </div>
        </div>
        <div className="right-content">
          <ul>
            <li><Link to="/invest">{t('footer_invest')}</Link></li>
            <li><Link to="/portfolio">{t('footer_portfolio')}</Link></li>
            <li><Link to="/about-us">{t('footer_about')}</Link></li>
          </ul>
          <ul>
            <li>{t('footerFunds')}</li>
            <li className="sub-li"><Link to="/funds-overview">{t('footerFundsOverview')}</Link></li>
            <li className="sub-li"><Link to="/liquid-crypto">{t('footerFundsLiquid')}</Link></li>
            <li className="sub-li"><Link to="/fixed-income">{t('footerFundsFixed')}</Link></li>
            <li className="sub-li"><Link to="/high-yield">{t('footerFundsHigh')}</Link></li>
          </ul>
        </div>
      </div>
      <div className="subfooter">
        <span>© Copyright - Patagonia Capital 2022</span>
      </div>
    </div>
  );
}
