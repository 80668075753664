/* eslint-disable max-len */
import React, { useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../assets/img/close.svg';

export default function Disclaimer() {
  const [hideDisclaimer, setHideDisclaimer] = useState('');
  const { t } = useTranslation();
  return (
    <div className={`disclaimer-component ${hideDisclaimer}`}>
      <button type="button" className="close-btn" onClick={() => setHideDisclaimer('hide')}>
        <img alt="Cerrar" src={closeIcon} title="Cerrar" />
      </button>
      <h2>
        Disclaimer
      </h2>
      <div>
        <p>
          {t('disclaimer_paragraph1')}
        </p>
        <p>
          {t('disclaimer_paragraph2')}
        </p>
        <p>
          {t('disclaimer_paragraph3')}
        </p>
        <p>
          {t('disclaimer_paragraph4')}
        </p>
        <p>
          {t('disclaimer_paragraph5')}

        </p>
        <p>
          {t('disclaimer_paragraph6')}
          {' '}
          <a href="mailto:info@patagoniacapital.net">info@patagoniacapital.net</a>
          .
        </p>
      </div>
    </div>
  );
}
