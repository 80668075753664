import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import arrowRight from '../../assets/img/flecha_derecha.svg';

export default function BlockchainNotice() {
  const { t } = useTranslation();
  return (
    <div className="blockchain-notice">
      <div className="text-content">
        <h2>{t('blockchainNotice_question')}</h2>
        <p>{t('blockchainNotice_paragraph')}</p>
      </div>
      <a href="mailto:info@patagoniacapital.net" className="btn-gral">
        {t('blockchainNotice_button')}
        <img alt="" src={arrowRight} />
      </a>
    </div>
  );
}
