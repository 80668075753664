import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import linkedin from '../../assets/img/linkedin_black.svg';

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className="aboutus-page">
      <div className="aboutus-content">
        <div className="aboutus-title-section">
          <h2 className="title-page">{t('aboutUs_title')}</h2>
          <p className="subtitle-page">
            {t('aboutUs_subtitle')}
          </p>
          <p className="subtitle-page">
            {t('aboutUs_paragraph')}
          </p>
        </div>
        <div className="social-media">
          <div>
            <h3>Social</h3>
            <a href="https://www.linkedin.com/company/patagonia-capital">
              <img alt="" src={linkedin} />
              LinkedIn
            </a>
          </div>
          <div>
            <h3>Email</h3>
            <a href="mailto:info@patagoniacapital.net">info@patagoniacapital.net</a>
          </div>
        </div>
      </div>
    </div>
  );
}
